/* CTA starts */

.ftco-hireme {
    background: #000000;
    padding: 0;
    margin-top: 100px;
    color: rgba(255, 255, 255, 0.9);
}

.ftco-hireme h2 {
    color: #fff;
    font-size: 34px;
    font-weight: 600;
}

@media (min-width: 992px) {
    .ftco-hireme img {
        margin-top: -90px;
    }
}